const age_info = () => {
  $(document).ready(() => {
    $('.age-icon').hide();
  });
  $('.baby-img').on('click', (event) => {
    console.log("test");
    $(event.currentTarget).parent().next().children('#age-baby').show();
    }).on('mouseenter', () => {
    $(event.currentTarget).parent().next().children('#age-baby').show();
    }).on('mouseleave', () => {
    $(event.currentTarget).parent().next().children('#age-baby').hide();
  });
  $('.toddler-img').on('click', (event) => {
    $(event.currentTarget).parent().next().children('#age-toddler').show();
    }).on('mouseenter', () => {
    $(event.currentTarget).parent().next().children('#age-toddler').show();
    }).on('mouseleave', () => {
    $(event.currentTarget).parent().next().children('#age-toddler').hide();
  });
  $('.child-img').on('click', (event) => {
    $(event.currentTarget).parent().next().children('#age-child').show();
    }).on('mouseenter', () => {
    $(event.currentTarget).parent().next().children('#age-child').show();
    }).on('mouseleave', () => {
    $(event.currentTarget).parent().next().children('#age-child').hide();
  });

}

export { age_info }
