import "bootstrap";
import { checkboxSelect } from "../functions/category_checkboxes.js"
import "../functions/slideout.js"

import 'mapbox-gl/dist/mapbox-gl.css'; // <-- you need to uncomment the stylesheet_pack_tag in the layout!

import { initMapbox } from '../plugins/init_mapbox';

import { initMapboxShow } from '../plugins/init_mapbox';

import { initMapboxEdit } from '../plugins/init_mapbox';

import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css'; // CSS for search field

import {jsTabs } from '../functions/js_tabs'

import { reviewStars } from '../functions/stars_review'

import { initAlgoliaPlace } from '../functions/algolia_places'


import { chooseCategory } from '../functions/category_choose'

import { age_info } from '../functions/age_icons'

import {preview} from '../functions/photo_input'

// import {photo_preview} from '../functions/image_preview'

import {fadeOut} from '../functions/notice_fadeout'

import { nav_search_display } from '../functions/navbar_search'

import { cookies_eu_function } from '../functions/cookies_eu'




initMapbox();
initMapboxShow();
initMapboxEdit();
jsTabs();
checkboxSelect();
reviewStars();
initAlgoliaPlace();
chooseCategory();
age_info();
preview();
// photo_preview();
fadeOut();
nav_search_display();
cookies_eu_function();


