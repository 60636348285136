
const jsTabs = () => {
  $(document).ready(() =>{
      $('#content').show();
      $('#fav').hide();
      $('#rev').hide();
      // $('#content').siblings('.profile-tabs').hide();
      $('#btn-content').addClass('tab-active');
    $('#btn-content').on('click', () => {
      $('#content').show();
      $('#fav').hide();
      $('#rev').hide();
      // $('#content').siblings('.profile-tabs').hide();
      $('#btn-content').addClass('tab-active');
      $('#btn-content').siblings().removeClass('tab-active');
    });
    $('#btn-fav').on('click', () => {
      $('#fav').show();
      $('#content').hide();
      $('#rev').hide();
      // $('#fav').siblings('.profile-tabs').hide();
      $('#btn-fav').addClass('tab-active');
      $('#btn-fav').siblings().removeClass('tab-active');
    });
    $('#btn-rev').on('click', () => {
      $('#rev').show();
      $('#content').hide();
      $('#fav').hide();
      // $('#rev').siblings('.profile-tabs').hide();
      $('#btn-rev').addClass('tab-active');
      $('#btn-rev').siblings().removeClass('tab-active');
    });
  })

}

export { jsTabs }






