const cookies_eu_function = () => {
//= require js.cookie
'use strict';
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  // Default ad_storage to 'denied'.
  gtag('consent', 'default', {
    'ad_storage': 'denied',
    'analytics_storage': 'denied'
  });
  gtag('js', new Date());
  gtag('config', 'UA-153638486-1', { 'anonymize_ip': true });

var windowIsTurbolinked = 'Turbolinks' in window;

var cookiesEu = {
  init: function() {
    var cookiesEuOKButton = document.querySelector('.js-cookies-eu-ok');
    var cookiesEuOkLimited = document.querySelector('.js-cookies-eu-limited');
    if (cookiesEuOKButton) {
      this.addListener(cookiesEuOKButton);
      // clear turbolinks cache so cookie banner does not reappear
      windowIsTurbolinked && window.Turbolinks.clearCache();
    }
    if (cookiesEuOkLimited) {
      this.addLimitedListener(cookiesEuOkLimited);
      // clear turbolinks cache so cookie banner does not reappear
      windowIsTurbolinked && window.Turbolinks.clearCache();
    }
  },

  addListener: function(target) {
    // Support for IE < 9
    if (target.attachEvent) {
      target.attachEvent('onclick', this.setCookie);

    } else {
      target.addEventListener('click', this.setCookie, false);
    }
  },

  addLimitedListener: function(target) {
    // Support for IE < 9
    if (target.attachEvent) {
      target.attachEvent('onclick', this.setCookieLimited);

    } else {
      target.addEventListener('click', this.setCookieLimited, false);
    }
  },

  setCookie: function() {
    var isSecure = location.protocol === 'https:';
    Cookies.set('cookie_eu_consented', true, { path: '/', expires: 365, secure: isSecure });

    var container = document.querySelector('.js-cookies-eu');
    container.parentNode.removeChild(container);
        // accept google analytics
      gtag('consent', 'update', {
        'ad_storage': 'granted',
        'analytics_storage': 'granted'
      });
      console.log("cookies accepted");
  },

    setCookieLimited: function() {
    var isSecure = location.protocol === 'https:';
    Cookies.set('cookie_eu_consented', true, { path: '/', expires: 365, secure: isSecure });

    var container = document.querySelector('.js-cookies-eu');
    container.parentNode.removeChild(container);
        // don't accept google analytics
      gtag('consent', 'update', {
        'ad_storage': 'denied',
        'analytics_storage': 'denied'
      });
      console.log("no analytics");
  }
};

(function() {
  function eventName(fallback) {
    return windowIsTurbolinked ? 'turbolinks:load' : fallback
  }

  var isCalled = false;

  function isReady() {
    // return early when cookiesEu.init has been called AND Turbolinks is NOT included
    // when Turbolinks is included cookiesEu.init has to be called on every page load
    if (isCalled && !windowIsTurbolinked) {
      return
    }
    isCalled = true;

    cookiesEu.init();
  }

  if (document.addEventListener) {
    return document.addEventListener(eventName('DOMContentLoaded'), isReady, false);
  }

  // Old browsers IE < 9
  if (window.addEventListener) {
    window.addEventListener(eventName('load'), isReady, false);
  } else if (window.attachEvent) {
    window.attachEvent(eventName('onload'), isReady);
  }
})();}

export { cookies_eu_function }
