const render = function (template, node) {
  if (!node) return;
  node.innerHTML = template;
};

const chooseCategory = () => {
  const chosenCategory = document.querySelector('#form-search option')
  if (chosenCategory === 'Tip')
    { return 'tip'; }
  else
    { return 'other'; }
}


export { chooseCategory }
