  import mapboxgl from 'mapbox-gl';
  import 'mapbox-gl/dist/mapbox-gl.css';
  import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';

const fitMapToMarkers = (map, markers) => {
  const bounds = new mapboxgl.LngLatBounds();
  markers.forEach(marker => bounds.extend([ marker.lng, marker.lat ]));
  map.fitBounds(bounds, { padding: 70, maxZoom: 15, duration: 1000 });
  map.addControl(new MapboxGeocoder({ accessToken: mapboxgl.accessToken }));
};



const initMapbox = () => {
  const mapElement = document.getElementById('map');
  const cityLocation = document.getElementById('map');
  // var sw = new mapboxgl.LngLat(-12.1901, 40.9665);
  // var ne = new mapboxgl.LngLat(35.5669, 70.9444);
  // var llb = new mapboxgl.LngLatBounds(sw, ne);
  if (mapElement) { // only build a map if there's a div#map to inject into
    mapboxgl.accessToken = mapElement.dataset.mapboxApiKey;
    const map = new mapboxgl.Map({
      container: 'map',
      style: 'mapbox://styles/mapbox/streets-v10',
      // LngLatBounds: llb
    });

    const markers = JSON.parse(mapElement.dataset.markers);

      markers.forEach((marker) => {

        const element = document.createElement('div');
        element.className = 'marker';
        element.style.backgroundImage = `url('${marker.image_url}')`;
        element.style.backgroundSize = 'contain';
        element.style.width = '50px';
        element.style.height = '50px';
        if (marker.infoWindow) {
                  const popup = new mapboxgl.Popup({ offset: 25 }).setHTML(marker.infoWindow.content);
        new mapboxgl.Marker(element)
          .setLngLat([ marker.lng, marker.lat ])
          .setPopup(popup)
          .addTo(map);
        } else { // map will be created even if there are no facilities with correct address
          new mapboxgl.Marker(element)
          .setLngLat([ marker.lng, marker.lat ])
          .addTo(map);
        }
      });

      fitMapToMarkers(map, markers);
      map.addControl(new mapboxgl.NavigationControl());

  }
};

const initMapboxShow = () => {
  const mapElementShow = document.getElementById('mapshow');


  if (mapElementShow) { // only build a map if there's a div#map to inject into
    mapboxgl.accessToken = mapElementShow.dataset.mapboxApiKey;
    const mapShow = new mapboxgl.Map({
      container: 'mapshow',
      style: 'mapbox://styles/mapbox/streets-v10'
    });

    const markersShow = JSON.parse(mapElementShow.dataset.markers);

    markersShow.forEach((marker) => {
      const element = document.createElement('div');
      element.className = 'marker';
      element.style.backgroundImage = `url('${marker.image_url}')`;
      element.style.backgroundSize = 'contain';
      element.style.width = '50px';
      element.style.height = '50px';
      new mapboxgl.Marker(element)
        .setLngLat([ marker.lng, marker.lat ])
        .addTo(mapShow);
    });

    fitMapToMarkers(mapShow, markersShow);
    mapShow.addControl(new mapboxgl.NavigationControl());
  }
};


const initMapboxEdit = () => {
  const mapElementEdit = document.getElementById('mapedit');
  let newPosition = '';


  if (mapElementEdit) { // only build a map if there's a div#map to inject into
    mapboxgl.accessToken = 'pk.eyJ1IjoibWF2aW1hIiwiYSI6ImNrMGF5dWQxaTBtdXgzbW1kNDR0bXE3dmUifQ.4gJL4Za3pAwVdq_2twV2uA';
const mapEdit = new mapboxgl.Map({
container: 'mapedit',
style: 'mapbox://styles/mapbox/streets-v10',
});

    const markersEdit = JSON.parse(mapElementEdit.dataset.markers);
    markersEdit.forEach((marker) => {
      const element = document.createElement('div');
      element.className = 'marker';
      element.style.backgroundImage = `url('${marker.image_url}')`;
      element.style.backgroundSize = 'contain';
      element.style.width = '50px';
      element.style.height = '50px';
      const markerer = new mapboxgl.Marker(element, {draggable: true})
        .setLngLat([ marker.lng, marker.lat ])
        .addTo(mapEdit);

        function onDragEnd() {
          let lngLat = markerer.getLngLat();
          document.getElementById("longitude").innerHTML = lngLat.lng;
          document.getElementById("latitude").innerHTML = lngLat.lat;
          document.getElementById("longitudeform").value = lngLat.lng;
          document.getElementById("latitudeform").value = lngLat.lat;
        }

        markerer.on('dragend', onDragEnd);

    });

  mapEdit.on('mousemove', function(e) {

  document.getElementById('info').innerHTML =
  // e.point is the x, y coordinates of the mousemove event relative
  // to the top-left corner of the map
  JSON.stringify(e.point) +
  '<br />' +
  // e.lngLat is the longitude, latitude geographical position of the event
  JSON.stringify(e.lngLat.wrap());
  });


  fitMapToMarkers(mapEdit, markersEdit);
  mapEdit.addControl(new mapboxgl.NavigationControl());

 }
};

export { initMapbox };

export { initMapboxShow };

export { initMapboxEdit };



